@font-face {
  font-family: videojs-record;
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAVUAAsAAAAACQQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPAAAAFY6l0dNY21hcAAAAYAAAABqAAABxPJEI45nbHlmAAAB7AAAAUwAAAJAcmm162hlYWQAAAM4AAAALQAAADYO+QZuaGhlYQAAA2gAAAAWAAAAJABhADlobXR4AAADgAAAAA4AAAAgAVAAAGxvY2EAAAOQAAAAEgAAABICyAH+bWF4cAAAA6QAAAAfAAAAIAEVADxuYW1lAAADxAAAATYAAAJemUJQLnBvc3QAAAT8AAAAWAAAAIV3bhWyeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGTQYpzAwMrAwCDHoAgk2aC0OAMTAw8DkGBlZsAKAtJcUxgcPjJ+ZGcwAHJZwCQDAyOIAADEDQVjeJztkUEKxEAIBGsyZoSwT1n2QTnlkP/DvGBi6z4jQrXYiIcW2IEefAODdtFQneG29DtH+sYvd0z+9LVCmzRmS91i1+LiwGMcvPVJvf+TK7EiM9wK1HuhX0wrlOrcC/1pjgJ1L/AHpD8V1QAAeJxFUWtvgzAMtBOTIMTQHkCRtlUVVPSxtquA0A/T+v9/1s7Qdg5cHEd2zmcypHakPTX0TXRpNo1vavfJefnD4cShz8tuBDpf9N34y0Prc1e3cMauLLZOUnEGlomTyEXATE9GDL5WsFXw2IuNIiue58CzpsQKeJ0nDnta0xsYhKH94jsDfXERauaKeYL43auXK/i4pJl/SwdKaYXsmeKcfskzrtFCky+5L/rQXTlURtSeLLhaa50V/LmYFHEFZyNjcOPEexxu9c+0A7ePR/07wb5B2U2onR8vYbHVflZTZ+dSVUni1JhcAwARL8YmRjw9ej5ipahaZwyO4DecuIqSKEoShcNtB/znrLEscvqiSatqXZGeploHisFxhzudmaoQ/md5J77xkGS4crdkyPOK7lUE96DuRDVBRKGaJLIv89DmUS5vdwCiPzEXImN4nGNgZGBgAOIpJpufxfPbfGXgZjAAijBcv5rShUwDRXWBJAcDE4gDADgXCdkAAAB4nGNgZGBgMGBgQCIZGVABBwAOcACbAAB4nGNgAAID3BgAE9ABUQAAAAAAAABCAGAAkAC8ANYA4gEgAAB4nGNgZGBg4GAwYGBmAAEmIOYCQgaG/2A+AwAOFwFSAHicfZA/asMwGMWf8680hlIo7ZRBUOhSYicZQ+cEOmbI7tiyY2NbRlYCuUEP0hP0EB17kJ6iL6qWZLAE0u+9732fQADu8Q0P5+Xhzp7n1cMN1T/3SY+OB+Rnx0P4mDse0X9zPMYr3h37eMCBE7zBLZ0JPhz3mP903Kf/5XhA/nE8xBN+HY8w8eB4jK03cezjxdPHPJGqaKdaxkonG5kdyujKvFRbqdtc1WIezC4La1lLHRmZiN1JtMdsYUwqUq0qsVK1kWWpRKNVIWMT7I1plmGYOj+IVYUjciSQUCjQYgpNjqk03Q0541+UiKi7kl21LVnTzalrCP5/gFlnx5pc264IhnfCrh1OPFv2ZVjQNUipU2YUKtLKTj+nS25Fp7G1ws41fHNvuxosEXKnV/nAvl79AXudcPYAAHicfcdLDoAgDADR1h8oXoVDNdBEFlhS0PObSOLSWb2BAf6zOOCIE864oEGLKxi6fWHN250iSyddMXW6GpT5fL0rB9HoayNt7hspezmkiQ+UWQngAd0/HZg=) format("woff");
  font-weight: normal;
  font-style: normal; }

.vjs-icon-av-perm {
  font-family: videojs-record;
  font-weight: normal;
  font-style: normal; }
  .vjs-icon-av-perm:before {
    content: "\F101"; }

.vjs-icon-video-perm {
  font-family: videojs-record;
  font-weight: normal;
  font-style: normal; }
  .vjs-icon-video-perm:before {
    content: "\F102"; }

.vjs-icon-audio-perm {
  font-family: videojs-record;
  font-weight: normal;
  font-style: normal; }
  .vjs-icon-audio-perm:before {
    content: "\F103"; }

.vjs-icon-screen-perm {
  font-family: videojs-record;
  font-weight: normal;
  font-style: normal; }
  .vjs-icon-screen-perm:before {
    content: "\F104"; }

.vjs-icon-record-start {
  font-family: videojs-record;
  font-weight: normal;
  font-style: normal; }
  .vjs-icon-record-start:before {
    content: "\F105"; }

.vjs-icon-record-stop {
  font-family: videojs-record;
  font-weight: normal;
  font-style: normal; }
  .vjs-icon-record-stop:before {
    content: "\F106"; }

.vjs-icon-photo-camera {
  font-family: videojs-record;
  font-weight: normal;
  font-style: normal; }
  .vjs-icon-photo-camera:before {
    content: "\F107"; }

/* General plugin style.
--------------------------------------------------------------------------------
*/
/* Position fullscreen control on right side of the player.
--------------------------------------------------------------------------------
*/
.vjs-record.video-js .vjs-control.vjs-fullscreen-control {
  position: absolute;
  right: 0; }

/* Ensure custom controls are always visible because
   the plugin hides and replace the video.js native mobile
   controls.
--------------------------------------------------------------------------------
*/
.vjs-record .vjs-using-native-controls .vjs-control-bar {
  display: flex !important; }

/* Handle responsive / fluid view.
--------------------------------------------------------------------------------
*/
.vjs-record.vjs-fluid wave.vjs-wavedisplay,
.vjs-record.vjs-fluid .vjs-record-canvas > canvas:nth-child(1),
.vjs-record.vjs-fluid .vjs-animation-display > img:nth-child(1) {
  top: 0;
  position: absolute !important;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%; }

/* Device Button (large centered button at startup)
--------------------------------------------------------------------------------
*/
.vjs-record .vjs-device-button.vjs-control {
  font-size: 3em;
  position: absolute;
  z-index: 2;
  /* Center it vertically */
  top: 50%;
  /* Center it horizontally */
  left: 50%;
  width: 4em;
  height: 2.8em;
  margin-top: -1.8000000000000001em;
  margin-left: -2.1em;
  display: block;
  cursor: pointer;
  opacity: 1;
  /* transition */
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  text-align: center;
  vertical-align: middle;
  /* border-radius */
  -webkit-border-radius: .8em;
  -moz-border-radius: .8em;
  border-radius: .8em;
  /* Need a slightly gray bg so it can be seen on black backgrounds */
  /* background-color-with-alpha */
  background-color: #07141e;
  background-color: rgba(7, 20, 30, 0.7);
  /* box-shadow */
  -webkit-box-shadow: 0 0 1em rgba(255, 255, 255, 0.25);
  -moz-box-shadow: 0 0 1em rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 1em rgba(255, 255, 255, 0.25); }

.vjs-record .vjs-error .vjs-device-button {
  display: none; }

.vjs-record button.vjs-device-button.vjs-control.vjs-icon-av-perm:before,
.vjs-record button.vjs-device-button.vjs-control.vjs-icon-audio-perm:before,
.vjs-record button.vjs-device-button.vjs-control.vjs-icon-video-perm:before,
.vjs-record button.vjs-device-button.vjs-control.vjs-icon-screen-perm:before {
  font-size: 2.4em; }

/* Record Toggle (start/stop recording button in controlbar)
--------------------------------------------------------------------------------
*/
.vjs-record .vjs-record-button.vjs-control {
  cursor: pointer; }

.vjs-record .vjs-error .vjs-record-button {
  display: none; }

.vjs-record .vjs-record-button.vjs-control.vjs-icon-record-start:before {
  font-size: 1.4em;
  line-height: 2.2em; }

.vjs-record .vjs-record-button.vjs-control.vjs-icon-record-stop:before {
  font-size: 2.2em;
  line-height: 1.4em; }

.vjs-record .vjs-play-control.vjs-control {
  cursor: pointer; }

/* Camera Button (snapshot try/retry)
--------------------------------------------------------------------------------
*/
.vjs-record .vjs-camera-button.vjs-control {
  cursor: pointer; }

.vjs-record .vjs-error .vjs-camera-button {
  display: none; }

.vjs-record .vjs-camera-button.vjs-control.vjs-icon-photo-camera:before {
  font-size: 2.0em;
  line-height: 1.4em; }

.vjs-record .vjs-camera-button.vjs-control.vjs-icon-replay:before {
  font-size: 2.2em;
  line-height: 1.4em; }

/* Record Indicator (dot in top-right corner visible when recording)
--------------------------------------------------------------------------------
*/
.vjs-record.video-js .vjs-control.vjs-record-indicator.vjs-hidden {
  display: block !important;
  opacity: 0;
  transform: translateX(1em) rotateX(-90deg) scale(0.9);
  transition: all .5s, transform .5s .25s; }

.vjs-record.video-js .vjs-control.vjs-record-indicator {
  width: .8em;
  height: .8em;
  background-color: #ff625e;
  line-height: 1.1em;
  border-radius: 50%;
  right: 2.5ex;
  top: 2ex;
  position: absolute;
  opacity: 1;
  transform: rotateX(0) scale(1.3);
  transition: all .5s, transform .2s .1s; }

.vjs-record.video-js .vjs-control.vjs-record-indicator:before {
  content: "REC";
  color: white;
  position: absolute;
  left: -2.7em;
  font-family: "Helvetica", sans-serif;
  animation-name: pulsetext;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  top: -0.1em;
  font-size: 0.8em;
  opacity: 1;
  transition: opacity .5s .2s; }

.vjs-record.video-js .vjs-control.vjs-record-indicator:after {
  content: "";
  display: block;
  height: calc(.8em - 4px);
  width: calc(.8em - 4px);
  border: 2px solid #ff625e;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transform-origin: center;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .25s; }

@keyframes pulsetext {
  from {
    opacity: .75; }
  25% {
    opacity: .5; }
  50% {
    opacity: .75; }
  75% {
    opacity: .5; }
  to {
    opacity: .75; } }

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1.6); }
  50% {
    opacity: .5;
    transform: scale(2.5); }
  to {
    opacity: 0;
    transform: scale(3.5); } }
